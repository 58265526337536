<template>
	<div>
		<b-table-simple sticky-header="60vh">
			<b-thead class="text-center">
				<b-tr>
					<b-th colspan="1" class="bg-transparent" style="border: none"></b-th>
					<b-th colspan="6" class="bg-info text-white rounded-top" style="border: none; font-size: 1rem">
						Categorias
					</b-th>
				</b-tr>
				<b-tr>
					<b-th rowspan="2">Estado</b-th>
					<b-th v-for="(category, index) in rows.categories" :key="index">
						{{ category.description }}
					</b-th>
					<b-th>Creado por</b-th>
					<b-th>Motivos</b-th>
					<b-th>Acciones</b-th>
				</b-tr>
			</b-thead>
			<b-tbody class="text-center">
				<b-tr v-for="(status, indexSt) in rows.statuses" :key="indexSt">
					<b-td class="text-center" style="font-weight: 600; text-transform: uppercase">
						{{ status.description }}
					</b-td>
					<b-td v-for="(category, indexCat) in status.categories" :key="indexCat" class="text-center">
						<div class="d-flex flex-column align-items-center justify-content-center" style="gap: 0.35rem">
							<span
								style="font-weight: 600; text-decoration: underline"
								class="text-primary"
								:class="{
									'cursor-pointer': category.counter > 0,
									'text-danger': category.counter == 0,
								}"
								@click="openSpecimensByStatus(status, category)"
							>
								{{ category.counter }}
							</span>
							<b-form-checkbox
								style="padding-left: 1.1rem"
								v-model="category.is_check"
								@change="changeStatusCat($event, status, category)"
							/>
						</div>
					</b-td>
					<b-td class="text-center">
						<p class="mb-0">{{ status.created_by.name }}</p>
						<p class="mb-0" v-if="status.created_at">{{ status.created_at | myGlobalDayShort }}</p>
						<p class="mb-0" v-else>---</p>
					</b-td>
					<b-td class="text-center">
						<div class="d-flex justify-content-center align-items-center gap-x-1">
							<b-form-checkbox v-model="status.is_reason" @change="changeStatusReason($event, status)" />
							<div class="position-relative">
								<feather-icon
									v-if="status.is_reason"
									class="cursor-pointer"
									@click="openReasons(status)"
									size="20"
									icon="SettingsIcon"
								/>
								<b-badge
									v-if="status.reason_counter > 0 && status.is_reason"
									variant="primary"
									style="top: -12px; right: -10px; padding: 0.2rem 0.4rem"
									class="position-absolute"
								>
									{{ status.reason_counter }}
								</b-badge>
							</div>
						</div>
					</b-td>
					<b-td class="text-center">
						<div class="d-flex justify-content-center">
							<b-button variant="flat-warning" @click="openEdit(status)">
								<feather-icon size="20" icon="EditIcon" />
							</b-button>
							<b-button variant="flat-danger" @click="remove(status)">
								<feather-icon size="20" icon="TrashIcon" />
							</b-button>
						</div>
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>

		<SpecimensByStatusModal
			v-if="showSpecimens"
			:statusId="statusId"
			:categoryId="categoryId"
			:info="infoSpec"
			@closing="showSpecimens = false"
			@refresh="getRows"
		/>

		<CreateStatusModal
			v-if="editModal.show"
			:indexTab="indexTab"
			:origin="'edit'"
			:info="editModal.info"
			@closing="editModal.show = false"
			@refresh="getRows"
		/>

		<ConfigureReasonModal
			v-if="configureReason.show"
			:info="configureReason.info"
			:statusId="statusId"
			@closing="refreshConfig"
			@refresh="getRows"
		/>
	</div>
</template>

<script>
import specimenStatusService from "@/services/specimenStatus.service"
import SpecimensByStatusModal from "@/views/brain/specimens/components/SpecimensByStatusModal.vue"
import CreateStatusModal from "../components/CreateStatusModal.vue"
import ConfigureReasonModal from "@/views/brain/specimens/components/ConfigureReasonModal.vue"

export default {
	components: { SpecimensByStatusModal, CreateStatusModal, ConfigureReasonModal },
	props: {
		// action: String,
		indexTab: Number,
		refreshNum: Number,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: { show: false, info: {} },
		categories: ["Padrillos", "Madrillas", "Gallos", "Gallinas", "Pollones", "Pollas"],
		showSpecimens: false,
		statusId: null,
		categoryId: null,
		editModal: { show: false, info: {} },
		configureReason: { show: false, info: null },
		infoSpec: null,
	}),
	methods: {
		getCounterForCategory(status, categoryId) {
			const category = status.categories.find((cat) => cat.id === categoryId)
			return category ? (category.counter ? category.counter : 0) : 0
		},
		getCheckForCategory(status, categoryId) {
			const category = status.categories.find((cat) => cat.id === categoryId)
			return category ? (category.is_check === 0 ? false : true) : false
		},
		openSpecimensByStatus(status, category) {
			this.statusId = status.id
			this.categoryId = category.category_id
			this.infoSpec = { status: status.description, category: category.name }
			if (category.counter > 0) this.showSpecimens = true
		},
		openEdit(data) {
			this.editModal.info = data
			this.editModal.show = true
		},
		openReasons(status) {
			this.statusId = status.id
			this.configureReason.show = true
			this.configureReason.info = status
		},
		async changeStatusReason(event, status) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se activara el administrador de motivos para el estado ${status.description}.`,
			})
			if (!isConfirmed) {
				this.$set(status, "is_reason", !event)
				return
			}
			this.isPreloading()
			try {
				const data = await specimenStatusService.changeStatusReason({
					status_id: status.id,
					is_reason: event ? 1 : 0,
				})
				this.getRows()
				this.showSuccessToast(data.message)
			} catch (error) {
				console.error(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async getRows() {
			this.isPreloading()
			try {
				const { data } = await specimenStatusService.getStatuses({ type: this.indexTab === 0 ? 1 : 0 })
				data.statuses.map((st) => {
					st.is_reason = st.is_reason === 1 ? true : false
					const categories = st.categories.map((cat) => {
						return {
							id: cat.id,
							category_id: cat.category_id,
							name: cat.name,
							counter: cat.counter,
							is_check: cat.is_check == 0 ? false : true,
						}
					})
					st.categories = categories
					return { ...st }
				})
				this.rows = { statuses: data.statuses, categories: data.categories }
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async refreshConfig(event) {
			this.configureReason.show = false
			this.configureReason.info = null
			if (!event.is_refresh) {
				this.rows.statuses.map((st) => {
					if (st.id === event.info.status.id) {
						st.categories.map((cat) => {
							if (cat.id === event.info.category.id) {
								cat.is_check = true
							}
						})
					}
				})
				return
			}
			await this.getRows()
		},
		async remove(status) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se eliminará el estado ${status.description}.`,
			})
			if (!isConfirmed) return

			if (status.categories.some((cat) => cat.counter > 0)) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`Esta categoría aún tiene ejemplares asociados y no se puede eliminar.`
				)
				this.loading = false
				return
			}

			this.isPreloading()

			try {
				await specimenStatusService.deleteStatus({ id: status.id })
				this.getRows()
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async changeStatusCat(event, status, category) {
			if (event || (!event && category.counter == 0)) {
				const { isConfirmed } = await this.showConfirmSwal({
					html: `<p>Se ${event ? "habilitara" : "deshabilitara"} el estado <span style="font-weight: bold;">${
						status.description
					}</span> para la categoria <span style="font-weight: bold;">${category.name}</span>.`,
				})

				if (!isConfirmed) {
					this.$set(category, "is_check", !event)
					return
				}
				this.isPreloading()
				try {
					const { data } = await specimenStatusService.activateStatusByCategory({
						id: category.id,
						is_check: event ? 1 : 0,
					})
					this.showSuccessToast(data.message)
				} catch (error) {
					console.error(error)
				} finally {
					this.isPreloading(false)
				}
			} else {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"AlertTriangleIcon",
					`Existen ejemplares usando el estado ${status.description} con categoria ${category.name}.`
				)
				this.$set(category, "is_check", !event)
				return
			}
		},
	},
	async created() {
		await this.getRows()
	},
	watch: {
		indexTab: async function () {
			await this.getRows()
		},
		refreshNum: async function () {
			await this.getRows()
		},
	},
}
</script>
