import axios from "@/axios"

const base = "api/brain/specimens/statuses/"

class SpecimenStatusService {
	// brain
	async getStatuses({ type }) {
		const { data } = await axios.get(`${base}`, { params: { type } })
		return data
	}
	async insertStatus({ description, in_galpon }) {
		const { data } = await axios.post(`${base}store`, { description, in_galpon })
		return data
	}
	async updateStatus({ status_id, description, in_galpon }) {
		const { data } = await axios.put(`${base}update`, { status_id, description, in_galpon })
		return data
	}
	async deleteStatus({ id }) {
		const { data } = await axios.delete(`${base}${id}`)
		return data
	}
	async getSpecimensByStatus({ status_id, category_id, search, page, perpage }) {
		const { data } = await axios.get(`${base}specimens`, {
			params: { status_id, category_id, search, page, perpage },
		})
		return data
	}
	async activateStatusByCategory(body) {
		try {
			const data = await axios.post(`${base}activate-by-category`, { ...body })
			return data
		} catch (error) {
			throw error
		}
	}
	async createSpecimenStatus({ name, type, is_reason, categories }) {
		const { data } = await axios.post(`${base}`, {
			name,
			type,
			is_reason,
			categories,
		})
		return data
	}
	async editSpecimenStatus({ id, name }) {
		const { data } = await axios.put(`${base}${id}`, {
			name,
		})
		return data
	}
	async getReasons({ status_id }) {
		const { data } = await axios.get(`${base}reasons`, {
			params: { status_id },
		})
		return data
	}
	async createReason({ status_id, reason }) {
		const { data } = await axios.post(`${base}reasons`, { status_id, reason })
		return data
	}
	async deleteReason({ id }) {
		const { data } = await axios.delete(`${base}reasons/${id}`)
		return data
	}
	async editReason({ id, reason, status_id }) {
		const { data } = await axios.put(`${base}reasons/${id}`, { reason, status_id })
		return data
	}
	async changeStatusReason({ status_id, is_reason }) {
		const { data } = await axios.put(`${base}reasons/change-status`, {
			status_id,
			is_reason,
		})
		return data
	}
}
export default new SpecimenStatusService()
